import axios from "axios";
import { env } from "./env";

// Retrieve the CSRF token from the HTML meta tags
//const csrfToken = document.querySelector('meta[name="_csrf"]').getAttribute('content');
//const csrfHeader = document.querySelector('meta[name="_csrf_header"]').getAttribute('content');

// Set the CSRF token as a default header for Axios
//axios.defaults.headers.common[csrfHeader] = csrfToken;

const HttpServices = {
  getData,
  postData,
  postFormData,
  patchData
};

let alertCount = 0;

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  // "Access-Control-Allow-Origin": "*",
  },
});

function getData(url) {
  const urlBody = env.baseUrl + url;
  return api({ url: urlBody, method: "GET" });
}

function postData(url, payLoad) {
  const urlBody = env.baseUrl + url;
  return api({ url: urlBody, data: payLoad, method: "POST" });
}

function patchData(url) {
  const urlBody = env.baseUrl + url;
  return api({ url: urlBody, method: "PATCH" });
}

function postFormData(url, payLoad) {
  const headerProps = {
    "Content-Type": "multipart/form-data",
  };
  const urlBody = env.baseUrl + url;
  return api({
    url: urlBody,
    headers: headerProps,
    data: payLoad,
    method: "POST",
  });
}

const onRequest = (config) => {
  if (config.headers) {
    const token = localStorage.getItem("token");
    if (token !== null || token !== "" || token !== undefined) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
};

const onRequestError = (error) => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  if (error && error.response.status === 401) {
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/signin";
    }, 3000);
    if (alertCount === 0) {
      alert(
        "Your login session is over!! Please Login again to continue the application.."
      );
      alertCount++;
    }
  }
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  if (error && error.response && error.response.status === 401) {
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/signin";
    }, 3000);
    if (alertCount === 0) {
      alert(
        "Your login session is over!! Please Login again to continue the application.."
      );
      alertCount++;
    }
  }
};

api.interceptors.request.use(onRequest, onRequestError);
api.interceptors.response.use(onResponse, onResponseError);

export default HttpServices;
