import React, { Component, Fragment } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import Icon from "../../../components/icon";
import usericon from "../../../assets/images/user.jpg";
import HttpServices from "./../../../environments/environment_urls";
import { env, env_url } from "./../../../environments/env";
import {
  convertDateFormat,
  calculateRemainingDays,
} from "../../../functions/common";
// import logo from '../../../assets/images/CriticalRiver_logo.png';

// const notificationList = (state, toggleNotifyDropdown) => {
//   return (
//     <Dropdown className="notification-dropdown" isOpen={state.notifyOpen} toggle={toggleNotifyDropdown}>
//       <DropdownToggle tag="div" className="notification-block p-2 mr-3">
//         <Icon iconName="mail-new" iconSize={22} />
//         <span className="notification-block-count">{state.notificationList ? state.notificationList.length : ''}</span>
//       </DropdownToggle>
//       {state.notificationList.length !== 0 ? (
//         <DropdownMenu className="notification-dropdown-menu" right>
//           {state.notificationList.slice(0, 4).map((item, index) => {
//             return (
//               <React.Fragment key={index}>
//                 <DropdownItem tag="div" className="notification-item">
//                   <Link className="d-flex" to="/notification-detail">
//                     <div className={"shortname shortname-bg-" + index}>{item.name.match(/\b(\w)/g).join('')}</div>
//                     <div className="content-description">
//                       <h5 className="title mb-0">{item.title.substr(0, 30)}</h5>
//                       <div className="label mb-1">{item.name}</div>
//                       <div className="label">{convertDateFormat(item.dateTime, 'DD-MM-YYYY HH:mm')}</div>
//                     </div>
//                   </Link>
//                 </DropdownItem>
//                 <DropdownItem divider />
//               </React.Fragment>
//             )
//           })
//           }
//           <div className="text-center"><Link className="view-all" to="/notification-detail">View All</Link></div>
//         </DropdownMenu>
//       ) : ''}
//     </Dropdown>
//   )
// }

// const taskList = (state, toggleTaskDropdown) => {
//   return (
//     <Dropdown className="task-dropdown" isOpen={state.taskOpen} toggle={toggleTaskDropdown}>
//       <DropdownToggle tag="div" className="task-block p-2 mr-3">
//         <Icon iconName="notification" iconSize={22} />
//         <span className="task-block-count">{state.taskList ? state.taskList.length : ''}</span>
//       </DropdownToggle>
//       {state.taskList.length !== 0 ? (
//         <DropdownMenu className="task-dropdown-menu" right>
//           {state.taskList.slice(0, 4).map((item, index) => {
//             return (
//               <React.Fragment key={index}>
//                 <DropdownItem tag="div" className="task-item">
//                   <Link className="d-flex" to="/my-tasks">
//                     <div className={"shortname shortname-bg-" + index}>{item.taskName ? item.taskName.match(/\b(\w)/g).join('') : ''}</div>
//                     <div className="content-description">
//                       <h5 className="title mb-0">{item.title ? item.title.substr(0, 30) : ''}</h5>
//                       <div className="label"> {item.trackId ? item.trackId.substr(0, 35) : 'NA'}</div>
//                       <div className="label">{convertDateFormat(item.startDate, 'DD-MM-YYYY')}</div>
//                     </div>
//                   </Link>
//                 </DropdownItem>
//                 <DropdownItem divider />
//               </React.Fragment>
//             )
//           })
//           }
//           <div className="text-center" onClick={handleClick}><Link className="view-all" to="/my-tasks">View All</Link></div>
//         </DropdownMenu>
//       ) : ''}
//     </Dropdown>

//   )
// }

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageOpts: [
        { value: "English", label: "English" },
        { value: "Arabic", label: "Arabic" },
      ],
      setNewHeaderLine: false,
      dropdownOpen: false,
      notifyOpen: false,
      userInfData: "",
      userOrgData: "",
      notificationList: [],
      taskOpen: false,
      taskList: [],
      pageNo: 1,
      totalRecords: 0,
      pagePerSize: 10,
      logoPath: "",
      orgName: "",
      notificationCount: 0,
      planInfo: [],
      supplierStatus: ""
    };
  }

  // changeLanguage = (selectedOption) => {
  //   console.log(`Selected Language:`, selectedOption);
  // };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleNotifyDropdown = () => {
    this.setState({ notifyOpen: !this.state.notifyOpen });
  };

  toggleTaskDropdown = () => {
    this.setState({ taskOpen: !this.state.taskOpen });
  };

  componentDidMount() {
    this.setState({ supplierStatus: localStorage.getItem("rejectedSupplier") });
    this.getMySubscriptionInfo();
    this.notificationData();
    this.getALLPendingTasks();
    this.userData();
    this.updateWindowDimensions();
    window.addEventListener("load", this.updateWindowDimensions);
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (this.state.planInfo.planName === "Trial" && window.innerWidth <= 1365) {
      this.setState({ setNewHeaderLine: true });
    } else {
      this.setState({ setNewHeaderLine: false });
    }
  };

  userData = () => {
    const url = env_url.getLoggedInUser;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        this.setState({
          userInfData: response.data,
          userOrgData: response.data.umOrgDtl,
        });
        this.getUserOrgProperties();
      }
    });

    const logoUrl = env_url.getOrganizationInfo;
    HttpServices.getData(logoUrl).then((response) => {
      if (response.data) {
        this.setState({
          logoPath: response.data.orgLogoPath,
          orgName: response.data.orgLegalName,
        });
      }
    });
  };

  getUserInfo = (state, props, toggleDropdown) => {
    return (
      this.state.supplierStatus === "Rejected" ?
        <Dropdown
          className={"user-dropdown " + (!state.setNewHeaderLine ? "ml-4" : "")}
          isOpen={state.dropdownOpen}
          toggle={toggleDropdown}
        >
          <DropdownToggle tag="span">
            <img
              className={"user-icon " + (state.dropdownOpen ? "active" : "")}
              src={
                state.userInfData?.photoPath
                  ? `data:image/png;base64,${state.userInfData.photoPath}`
                  : usericon
              }
              alt="user"
            />
          </DropdownToggle>
          <DropdownMenu className="user-dropdown-menu" right>
            {state.userInfData?.name ? (
              <React.Fragment>
                <h4 className="user-name">{state.userInfData?.name}</h4>
                <p className="user-role">{state.userInfData?.designation}</p>
              </React.Fragment>
            ) : (
              ""
            )}
            <DropdownItem divider />
            <DropdownItem className="menu-option">
              <Link onClick={this.logOutUser}>
                <Icon iconName="power" className="mr-2" iconSize={22} /> Logout
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        :
        <Dropdown
          className={"user-dropdown " + (!state.setNewHeaderLine ? "ml-4" : "")}
          isOpen={state.dropdownOpen}
          toggle={toggleDropdown}
        >
          <DropdownToggle tag="span">
            <img
              className={"user-icon " + (state.dropdownOpen ? "active" : "")}
              src={
                state.userInfData?.photoPath
                  ? `data:image/png;base64,${state.userInfData.photoPath}`
                  : usericon
              }
              alt="user"
            />
          </DropdownToggle>
          <DropdownMenu className="user-dropdown-menu" right>
            {state.userInfData?.name ? (
              <React.Fragment>
                <h4 className="user-name">{state.userInfData?.name}</h4>
                <p className="user-role">{state.userInfData?.designation}</p>
              </React.Fragment>
            ) : (
              ""
            )}
            <DropdownItem divider />
                      {/* <DropdownItem
              className={"menu-option " + (props.profile ? "active" : "")}
            >
              <Link to="/account">
                <Icon iconName="user" className="mr-2" iconSize={22} />
                Profile
              </Link>
            </DropdownItem>
            <DropdownItem className="menu-option">
              <Link to="/feedback">
                <Icon iconName="edit1" className="mr-2" iconSize={22} /> Feedback
              </Link>
            </DropdownItem> */}

            <DropdownItem className="menu-option">
              <Link onClick={this.logOutUser}>
                <Icon iconName="power" className="mr-2" iconSize={22} /> Logout
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
    );
  };

  getUserOrgProperties = () => {
    env.baseCountry = this.state.userOrgData.orgCountry;
    env.baseCurrency = this.state.userOrgData.currency;
    env.dateFormat = this.state.userOrgData.dateFormat;
    env.taxName = this.state.userOrgData.defaultTax;
    env.paymentTerm = this.state.userOrgData.defaultPaymentTerms;
    env.orgId = this.state.userOrgData.id
    env.suppId = this.state.userInfData.id
  };

  notificationData = (pageNumber, pageSize) => {
    let notificationList = [];
    const url = env_url.getALLEmails;
    const formData = new FormData();
    formData.append("pagenum", pageNumber ? pageNumber - 1 : 0);
    formData.append("pagesize", pageSize ? pageSize : env.pageSize);
    HttpServices.postData(url, formData).then((response) => {
      if (response.data) {
        response.data.map((item) => {
          const e = {
            id: item.id,
            title: item.notifSubject,
            name: item.fromUser,
            trackId: item.id,
            dateTime: item.recievedDate,
          };
          return notificationList.push(e);
        });

        let tRecs = 0;
        if (notificationList.length > 0)
          tRecs = notificationList[0].totalRecords;

        this.setState({
          notificationList: notificationList,
          pageNo: pageNumber,
          pagePerSize: pageSize,
          totalRecords: tRecs,
        });
      }
    });
    const countUrl = env_url.getALLUnReadEmailsCount;
    HttpServices.getData(countUrl).then((response) => {
      if (response.data) {
        this.setState({ notificationCount: response.data });
      }
    });
  };

  getALLPendingTasks = () => {
    let taskList = [];
    const url = env_url.getALLPendingTasks;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        response.data.map((item) => {
          const e = {
            id: item.contextSite,
            title: item.subject,
            status: item.currentStatus,
            trackId: item.initiator,
            dateTime: item.inboxDate,
            taskName: item.taskName,
          };
          return taskList.push(e);
        });
        this.setState({ taskList: taskList });
      }
    });
  };

  getMySubscriptionInfo = () => {
    const url = env_url.getMySubscriptionInfo;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        let planInfo = response.data.subscriptions[0];
        this.setState({
          planStartDate: planInfo.startDate,
          planEndDate: planInfo.expiryDate,
        });
        const subscriptionPlan =
          env_url.getSubscriptionPlanById +
          "/" +
          response.data.subscriptions[0]?.planId;
        HttpServices.getData(subscriptionPlan).then((planInfo) => {
          this.setState({
            planInfo: planInfo && planInfo.data,
          });
          setTimeout(() => {
            this.updateWindowDimensions();
          }, 500);
        });
      }
    });
  };

  logOutUser = () => {
    const loginPath = localStorage.getItem("loginPath");
    if (loginPath === "B2C_Login" || loginPath === "Application") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/signin";
    } else {
      const url = env_url.logOut;
      HttpServices.postData(url).then((response) => {
        console.log(response);
        if (response.data === "Logout successful") {
          localStorage.clear();
          window.location.href = "/signin";
        }
      }
      )
    }
  };

  handleClick = () => {
    console.log("reached here");
    this.setState({ taskOpen: false, notifyOpen: false });
  };

  taskList = (state, toggleTaskDropdown) => {
    return (
      <Dropdown
        className="task-dropdown"
        isOpen={state.taskOpen}
        toggle={toggleTaskDropdown}
      >
        <DropdownToggle tag="div" className="task-block p-2 mr-3">
          <Icon iconName="notification" iconSize={22} />
          <span className="task-block-count">
            {state.taskList ? state.taskList.length : ""}
          </span>
        </DropdownToggle>
        {state.taskList.length !== 0 ? (
          <DropdownMenu className="task-dropdown-menu" right>
            {state.taskList.slice(0, 4).map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <DropdownItem tag="div" className="task-item">
                    <Link className="d-flex" to="/my-tasks">
                      <div className={"shortname shortname-bg-" + index}>
                        {item.taskName
                          ? item.taskName.match(/\b(\w)/g).join("")
                          : ""}
                      </div>
                      <div className="content-description">
                        <h5 className="title mb-0">
                          {item.title ? item.title.substr(0, 30) : ""}
                        </h5>
                        <div className="label">
                          {" "}
                          {item.trackId ? item.trackId.substr(0, 35) : "NA"}
                        </div>
                        <div className="label">
                          {convertDateFormat(item.startDate, "DD-MM-YYYY")}
                        </div>
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem divider />
                </React.Fragment>
              );
            })}
            <div className="text-center" onClick={this.handleClick}>
              <Link className="view-all" to="/my-tasks">
                View All
              </Link>
            </div>
          </DropdownMenu>
        ) : (
          ""
        )}
      </Dropdown>
    );
  };

  notificationList = (state, toggleNotifyDropdown) => {
    return (
      <Dropdown
        className="notification-dropdown"
        isOpen={state.notifyOpen}
        toggle={toggleNotifyDropdown}
      >
        <DropdownToggle tag="div" className="notification-block p-2">
          <Icon iconName="mail-new" iconSize={22} />
          <span className="notification-block-count">
            {state.notificationCount ? state.notificationCount : 0}
          </span>
        </DropdownToggle>
        {state.notificationList.length !== 0 ? (
          <DropdownMenu className="notification-dropdown-menu" right>
            {state.notificationList.slice(0, 4).map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <DropdownItem tag="div" className="notification-item">
                    <Link className="d-flex" to="/notification-detail">
                      <div className={"shortname shortname-bg-" + index}>
                        {item.name.match(/\b(\w)/g).join("")}
                      </div>
                      <div className="content-description">
                        <h5 className="title mb-0">
                          {item.title.substr(0, 30)}
                        </h5>
                        <div className="label mb-1">{item.name}</div>
                        <div className="label">
                          {convertDateFormat(item.dateTime, "DD-MM-YYYY HH:mm")}
                        </div>
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem divider />
                </React.Fragment>
              );
            })}
            <div className="text-center" onClick={this.handleClick}>
              <Link className="view-all" to="/notification-detail">
                View All
              </Link>
            </div>
          </DropdownMenu>
        ) : (
          ""
        )}
      </Dropdown>
    );
  };

  render() {
    const state = this.state;
    const props = this.props;
    return (
      <Fragment>
        <header>
          <div className="header-block">
            <div className="header-content">
              <h3
                className="header-content-title d-flex align-items-center mb-0"
              >
                <div className="btn-menu-toggle" onClick={() => props.handleToggleSidebar(true)}>
                  <Icon iconName="Paragraph mr-4" iconSize={24} />
                </div>
                {props.linkToBack && (
                  <Link to={props.linkToBack ? props.linkToBack : "/"}>
                    <Icon iconName="back-arrow mr-2" iconSize={24} />
                  </Link>
                )}
                {props.titleText ? (
                  props.titleText
                ) : state.logoPath && state.planInfo.planName !== "Trial" ? (
                  <img
                    src={`data:image/png;base64,${state.logoPath}`}
                    alt="Company Logo"
                    className="header-logo"
                  />
                ) : (
                  state.orgName
                )}
              </h3>

              {state.planInfo &&
                state.planInfo.planName === "Trial" &&
                calculateRemainingDays(state.planStartDate, state.planEndDate) >
                0 ? (
                <div className="main-screen">
                  <h3 className="header-content-title d-flex align-items-center mb-0">
                    <div className="label ml-2">
                      Your Trial Period will expire in{" "}
                      {calculateRemainingDays(
                        state.planStartDate,
                        state.planEndDate
                      )}{" "}
                      days.
                    </div>
                    <Link
                      className="btn btn-primary btn-lg ml-20"
                      to="/my-subscription"
                    >
                      Buy
                    </Link>
                    <a
                      href="https://prokraya.com/book-demo"
                      target="_blank"
                      className="btn btn-primary btn-lg ml-10"
                      rel="noreferrer"
                    >
                      Request Demo
                    </a>
                  </h3>
                </div>
              ) : (
                ""
              )}

              <div className="d-flex align-items-center">
                <span
                  className="d-flex align-items-center"
                >
                  {!state.setNewHeaderLine && state.userInfData?.name ? (
                    <span className="main-screen">
                      Welcome,&nbsp;<b>{state.userInfData?.name}</b>&nbsp;&nbsp;
                    </span>
                  ) : (
                    ""
                  )}

                  {this.taskList(state, this.toggleTaskDropdown)}

                  {this.notificationList(state, this.toggleNotifyDropdown)}
                </span>

                {/* {!props.supplier && (
                <div className="lang-select d-flex align-items-center mr-3 px-2">
                  <Icon iconName="language-selection" iconSize={22} />
                  <Input type="select"
                    options={state.languageOpts}
                    getChanges={this.changeLanguage}
                    isSearchable={false}
                    placeholderText="Choose Language"
                    defaultValue={state.languageOpts[0]}
                  />
                </div>
              )} */}
                {!state.setNewHeaderLine &&
                  state.userInfData &&
                  state.planInfo?.planName &&
                  this.getUserInfo(state, props, this.toggleDropdown)}
              </div>

              {/* {(props.quickLinksList && props.quickLinksList.length > 0) && (
              <div className="nav-another-menu">
                <ul className="nav-ul-menu">
                  {props.quickLinksList.map((item, index) => {
                    return (
                      <li key={index}><Link className={item.selected ? "activate" : "menu-option"} to={item.to ? item.to : '/'}>{item.name}</Link></li>
                    )
                  })}
                </ul>
              </div>
            )} */}
            </div>
          </div>
        </header>
        {state.planInfo &&
          state.planInfo.planName === "Trial" &&
          calculateRemainingDays(state.planStartDate, state.planEndDate) >
          0 ? (
          <div className="mobile-screen">
            <h3 className="header-content-title d-flex align-items-center mb-0">
              <div className="label ml-2">
                Your Trial Period will expire in{" "}
                {calculateRemainingDays(
                  state.planStartDate,
                  state.planEndDate
                )}{" "}
                days.
              </div>
              <Link
                className="btn btn-primary btn-lg ml-20"
                to="/my-subscription"
              >
                Buy
              </Link>
              <a
                href="https://prokraya.com/book-demo"
                target="_blank"
                className="btn btn-primary btn-lg ml-10"
                rel="noreferrer"
              >
                Request Demo
              </a>
            </h3>
          </div>
        ) : (
          ""
        )}

        {state.setNewHeaderLine &&
          state.planInfo?.planName &&
          state.userInfData?.name ? (
          <div className="user-block-head d-flex align-items-center justify-content-end">
            <span>
              Welcome,&nbsp;<b>{state.userInfData?.name}</b>&nbsp;&nbsp;
            </span>
            {this.getUserInfo(state, props, this.toggleDropdown)}
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

export default React.memo(Header);
