const ErrorFallback = () => {
  return (
    <div className="alert alert-danger m-2" role="alert">
      <b>Something went wrong!</b>
      <br />
      <p className="mb-0">
        Your login session is over!! Please Login again to continue the
        application..
      </p>
    </div>
  );
};

const PrivateRoute = (props) => {
  const storedToken = localStorage.getItem("token");
  if (storedToken) {
    return props.children;
  } else {
    <ErrorFallback />;
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/signin";
    }, 5000);
  }
};

export { ErrorFallback, PrivateRoute };
