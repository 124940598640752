import moment from "moment";
// import { compareAsc, format } from 'date-fns'
import IBAN from "../functions/iban"

// Convert dateFormat
const convertDateFormat = (value, formatOpt) => {
  let convertedDate = moment(value).format(formatOpt);
  if (convertedDate) return convertedDate;
  return "";
};

//   DatePickerFormatUpdate
const DatePickerFormatUpdate = (dateFormat) => {
  // let str = "MM-DD-YYYY";
  const mapObj = {
    DD: "dd",
    D: "d",
    dd: "dd",
    YYYY: "yyyy",
    YY: "yy",
    yyyy: "yyyy",
  };
  const finalstr = dateFormat.replace(
    /\b(?:DD|D|YYYY|YY)\b/gi,
    (matched) => mapObj[matched]
  );
  return finalstr;
};
// // Convert dateFormat
// const convertDateFormatFns = (value, formatOpt) => {
// 	let convertedDate = format(new Date(value), `${"'" + formatOpt + "'"}`);
// 	if (convertedDate) return convertedDate;
// 	return '';
// }

// Extract currency symbol

const extrctCurrencySymbol = (countryCode) => {
  const currencyList = [
    { currency: "INR", Symbol: "₹" },
    { currency: "USD", Symbol: "$" },
    { currency: "AED", Symbol: "AED" },
    { currency: "MMK", Symbol: "K" },
  ];

  const currencySymbol = currencyList.filter(
    (currency) => currency.currency === countryCode
  );
  if (currencySymbol.length > 0) {
    return currencySymbol[0].Symbol;
  } else {
    return countryCode;
  }
};
// Convert Amount
const convertAmount = (value, formatOpt) => {
  let currencyType = "aed";
  if (value) {
    if (formatOpt === "INR") currencyType = "en-IN";
    else if (formatOpt === "AED") currencyType = "aed";
    return value.toLocaleString(currencyType, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (value === null || value === undefined) {
    value = 0;
    return value.toLocaleString(currencyType, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return value;
};

// format bytes to kbs
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  //const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
};

// email validation

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validatePassword = (password) => {
  const capitalLetterRegex = /[A-Z]/;
  const numberRegex = /[0-9]/;
  const minLength = 8;
  const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
  return (
    capitalLetterRegex.test(password) &&
    numberRegex.test(password) &&
    password.length >= minLength &&
    specialCharacterRegex.test(password)
  );
};



//Round off decimal to precision-2

const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};
// WebUrl validation

const validateWebUrl = (url) => {
  const webUrl = /(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/; // eslint-disable-line
  return webUrl.test(url);
};

// Text Extract
const extractText = (value) => {
  let characterLimit = 20;
  let dotSet = false;
  if (value && value.length > 0) {
    if (value.length > characterLimit) dotSet = true;
    let val = value.substr(0, characterLimit);
    return val + " " + (dotSet ? "..." : "");
  }
  return "";
};

// Text Extract
const extractSmallText = (value) => {
  let characterLimit = 10;
  let dotSet = false;
  if (value && value.length > 0) {
    if (value.length > characterLimit) dotSet = true;
    let val = value.substr(0, characterLimit);
    return val + " " + (dotSet ? "..." : "");
  }
  return "";
};

// Text Extract
const extractbelowMediumText = (value) => {
  let characterLimit = 15;
  let dotSet = false;
  if (value && value.length > 0) {
    if (value.length > characterLimit) dotSet = true;
    let val = value.substr(0, characterLimit);
    return val + " " + (dotSet ? "..." : "");
  }
  return "";
};

// Text Extract
const extractMediumText = (value) => {
  let characterLimit = 25;
  let dotSet = false;
  if (value && value.length > 0) {
    if (value.length > characterLimit) dotSet = true;
    let val = value.substr(0, characterLimit);
    return val + " " + (dotSet ? "..." : "");
  }
  return "";
};

// Text Extract
const extractLargeText = (value) => {
  let characterLimit = 40;
  let dotSet = false;
  if (value && value.length > 0) {
    if (value.length > characterLimit) dotSet = true;
    let val = value.substr(0, characterLimit);
    return val + " " + (dotSet ? "..." : "");
  }
  return "";
};

// Text Extract
const extractExtraLargeText = (value) => {
  let characterLimit = 60;
  let dotSet = false;
  if (value && value.length > 0) {
    if (value.length > characterLimit) dotSet = true;
    let val = value.substr(0, characterLimit);
    return val + " " + (dotSet ? "..." : "");
  }
  return "";
};

// Capitalize Word
const capitalizeWord = (value) => {
  if (value && value.length > 0) {
    return value.toLowerCase().replace(/\b./g, function (word) {
      return word.toUpperCase();
    });
  }
  return "";
};

// Lowercase Word
const lowercaseWord = (value) => {
  if (value && value.length > 0) {
    return value.toLowerCase().replace(/\b./g, function (word) {
      return word.toLowerCase();
    });
  }
  return "";
};

// Get file Extension
const getExtension = (file) => {
  let fileName = file.name || file.fileName;
  let nameLen = fileName.length;
  let lastDotPos = fileName.lastIndexOf(".");
  if (lastDotPos !== -1) {
    return fileName.substr(lastDotPos + 1, nameLen);
  }
};

const calculateRemainingDays = (planStartDate, planEndDate) => {
  let planStart = new Date(planStartDate);
  let planEnd = new Date(planEndDate);
  let startDate = new Date(
    `${planStart.getUTCFullYear()}/${planStart.getUTCMonth() + 1
    }/${planStart.getUTCDate()}`
  );

  let endDate = new Date(
    `${planEnd.getUTCFullYear()}/${planEnd.getUTCMonth() + 1
    }/${planEnd.getUTCDate()}`
  );

  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  let todaysDate = new Date();
  let currentDate = new Date(
    `${todaysDate.getUTCFullYear()}/${todaysDate.getUTCMonth() + 1
    }/${todaysDate.getUTCDate()}`
  );

  // let startDate = new Date(moment(planStartDate).format("YYYY, MM, DD"));
  // let endDate = new Date(moment(planEndDate).format("YYYY, MM, DD"));
  // console.log("startDate", startDate, "endDate", endDate);
  let remainingDays;

  if (startDate > currentDate) {
    remainingDays =
      (endDate - startDate) / oneDay > 0 ? (endDate - startDate) / oneDay : 0;
  } else {
    remainingDays =
      (endDate - currentDate) / oneDay > 0
        ? (endDate - currentDate) / oneDay
        : (endDate - currentDate) / oneDay;
  }
  return Math.round(remainingDays);
};

const convertLocalDateToUTCDate = (date) => {
  var dateLocal = new Date(date);
  var newDate = new Date(
    dateLocal.getTime() + dateLocal.getTimezoneOffset() * 60 * 1000
  );
  return newDate;
};
const convertUTCDateToLocalDate = (date) => {
  var dateLocal = new Date(date);
  var newDate = new Date(
    dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
  );
  return newDate;
};

function evaluateMath(str) {
  for (var i = 0; i < str.length; i++) {
    if (isNaN(str[i]) && !["+", "-", "/", "*", "%", "**"].includes(str[i])) {
      return NaN;
    }
  }
  try {
    return Math.eval(str);
  } catch (e) {
    if (e.name !== "SyntaxError") throw e;
    return NaN;
  }
}

function ibanNumberCheck(value) {
  var lengthMap = getLengthMap();

    //cleanup
    value = value.toString().toUpperCase().replace(/\s/g, '').replace(/[-]/g, '');
    //check if alphanumeric
    if (!/^[a-zA-Z0-9]+$/.test(value)) return false;
    //extract countrycode
    var countryCode = value.substring(0, 2);
    //check if letter
    if (!/([a-z]+[\s\-]?)*/i.test(countryCode)) return false;
    //check string length
    if (value.length != lengthMap[countryCode]) return false;

    value = value.concat(value.substring(0, 4)).substring(4);
    value = value.replace(countryCode, countryCodeToStringValue(countryCode));

    return modulo(value, 97) == 1;

    function countryCodeToStringValue(countryCode) {
        return "".concat(((countryCode.charCodeAt(0)) - 55).toString() + (countryCode.charCodeAt(1) - 55).toString());
    }

    function modulo(divident, divisor) {
        var quantization = 12;
        while (quantization < divident.length) {
            var part = divident.substring(0, quantization);
            divident = (part % divisor) + divident.substring(quantization);
        }
        return divident % divisor;
    }

    function getLengthMap() {
        var lengthMap = {};
        lengthMap["AD"] = 24;
        lengthMap["AT"] = 20;
        lengthMap["AZ"] = 28;
        lengthMap["BH"] = 22;
        lengthMap["BE"] = 16;
        lengthMap["BA"] = 20;
        lengthMap["BR"] = 29;
        lengthMap["BG"] = 22;
        lengthMap["CR"] = 21;
        lengthMap["HR"] = 21;
        lengthMap["CY"] = 28;
        lengthMap["CZ"] = 24;
        lengthMap["DK"] = 18;
        lengthMap["DO"] = 28;
        lengthMap["EE"] = 20;
        lengthMap["FO"] = 18;
        lengthMap["FI"] = 18;
        lengthMap["FR"] = 27;
        lengthMap["DE"] = 22;
        lengthMap["GR"] = 27;
        lengthMap["GI"] = 23;
        lengthMap["GL"] = 18;
        lengthMap["GT"] = 28;
        lengthMap["HU"] = 28;
        lengthMap["IS"] = 26;
        lengthMap["IE"] = 22;
        lengthMap["IL"] = 23;
        lengthMap["IT"] = 27;
        lengthMap["JO"] = 30;
        lengthMap["KZ"] = 20;
        lengthMap["KW"] = 30;
        lengthMap["LV"] = 21;
        lengthMap["LB"] = 28;
        lengthMap["LI"] = 21;
        lengthMap["LT"] = 20;
        lengthMap["LU"] = 20;
        lengthMap["MK"] = 19;
        lengthMap["MT"] = 31;
        lengthMap["MR"] = 27;
        lengthMap["MU"] = 30;
        lengthMap["MC"] = 27;
        lengthMap["MD"] = 24;
        lengthMap["ME"] = 22;
        lengthMap["NL"] = 18;
        lengthMap["NO"] = 15;
        lengthMap["PK"] = 24;
        lengthMap["PS"] = 29;
        lengthMap["PL"] = 28;
        lengthMap["PT"] = 25;
        lengthMap["QA"] = 29;
        lengthMap["RO"] = 24;
        lengthMap["SM"] = 27;
        lengthMap["SA"] = 24;
        lengthMap["RS"] = 22;
        lengthMap["SK"] = 24;
        lengthMap["SI"] = 19;
        lengthMap["ES"] = 24;
        lengthMap["SE"] = 24;
        lengthMap["CH"] = 21;
        lengthMap["TN"] = 24;
        lengthMap["TR"] = 26;
        lengthMap["AE"] = 23;
        lengthMap["GB"] = 22;

        return lengthMap;
    }
}

const accountNumbercheck = (accountNo, ibanNo) => {
  //check account no also exists in IBAN no 
  let ibanNumber = ibanNo;
  if (!(ibanNumber.indexOf(accountNo) > -1)) {
    return "Account no provided doesnt contain in IBAN No, Please check again!";
  } else {
    return true
  }
}
export {
  convertDateFormat,
  convertAmount,
  capitalizeWord,
  lowercaseWord,
  extractText,
  extractSmallText,
  extractLargeText,
  extractbelowMediumText,
  extractExtraLargeText,
  getExtension,
  formatBytes,
  extrctCurrencySymbol,
  validateEmail,
  validateWebUrl,
  DatePickerFormatUpdate,
  roundToTwo,
  extractMediumText,
  calculateRemainingDays,
  convertLocalDateToUTCDate,
  convertUTCDateToLocalDate,
  evaluateMath,
  validatePassword,
  ibanNumberCheck,
  accountNumbercheck
};