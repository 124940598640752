import { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CustomToast extends Component {
    showToast = () => {
        const config = {
            position: this.props.position,
            className: "custom-toast" + (this.props.className ? " " + this.props.className : "")
        }
        if (this.props.success) {
            toast.dark(this.props.toastMsg, config);
        } else if (this.props.error) {
            toast.error(this.props.toastMsg, config);
        }
    }

    render() {
        return ""
    }
}

export default CustomToast;