import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HeaderLayout from "./HeaderLayout";
import ChatBot from "../components/chat-bot";

// landing
const Forbidden = lazy(() => import("../modules/forbidden"));
const AccessDenied = lazy(() => import("../modules/user-acess"));
const UserCheck = lazy(() => import("../modules/user-check"));
const SiteLanding = lazy(() => import("../modules/site"));
const Signin = lazy(() => import("../modules/landing/signin"));
const SigninNew = lazy(() => import("../modules/landing/signin/signInNew"));
const Signup = lazy(() => import("../modules/landing/signup"));
const Forgotpassword = lazy(() => import("../modules/landing/forgotpassword"));
const Resetpassword = lazy(() => import("../modules/landing/resetpassword"));
const AboutUs = lazy(() => import("../modules/site/about-us"));
const Products = lazy(() => import("../modules/site/products"));
const Pricing = lazy(() => import("../modules/site/pricing"));
const FreeTrail = lazy(() => import("../modules/landing/free-trail"));
const BookDemo = lazy(() => import("../modules/landing/book-demo"));
const ContactUs = lazy(() => import("../modules/site/contact"));
const SupplierManagement = lazy(() => import("../modules/site/supplier-management"));
const PurchaseRequisition = lazy(() => import("../modules/site/purchase-requisition"));
const ContractManagement = lazy(() => import("../modules/site/contract-management"));
const EInvoicing = lazy(() => import("../modules/site/e-invoicing"));
const ESourcing = lazy(() => import("../modules/site/e-sourcing"));
const SpendAnalytics = lazy(() => import("../modules/site/spend-analytics"));
const Integrations = lazy(() => import("../modules/site/integrations"));
const Blogs = lazy(() => import("../modules/site/blogs"));
const BlogDetails = lazy(() => import("../modules/site/blogs/blog-view"));
const PrivacyPolicy = lazy(() => import("../modules/site/privacy-policy"));
const SupplierUserManual = lazy(() => import("../modules/site/supplier-user-manual"));
const Careers = lazy(() => import("../modules/site/careers"));

// supplier
const CompanyDetails = lazy(() =>
  import("../modules/supplier/company-details")
);
const ScopeofSupply = lazy(() => import("../modules/supplier/scopeof-supply"));
const ContactDetails = lazy(() =>
  import("../modules/supplier/contact-details")
);
const Banking = lazy(() => import("../modules/supplier/banking"));
const Certificates = lazy(() => import("../modules/supplier/certificates"));
const Dashboard = lazy(() => import("../modules/dashboard"));
const SupplierApproval = lazy(() => import("../modules/supplier/sup-aproval"));
const SupplierListing = lazy(() => import("../modules/supplier/sup-listing"));
const UserFeedback = lazy(() => import("../modules/common/feedback"));
const SupplierUsers = lazy(() => import("../modules/supplier/supplier-users"));
//const FeedbackAnalysis = lazy(() => import('../modules/common/feedback-analysis'));
//const SupplierSurvey = lazy(() => import('../modules/supplier/survey'));
//const NewSurvey = lazy(() => import('../modules/supplier/new-survey'));
//const SurveyTemplate = lazy(() => import('../modules/supplier/survey-template'));
// const CreateTemplate = lazy(() => import('../modules/supplier/create-template'));
//const SelectSupplier = lazy(() => import('../modules/supplier/select-supplier'));
const SupplierPreview = lazy(() => import("../modules/supplier/sup-preview"));
// esource
const PRLanding = lazy(() => import("../modules/esource/pr-landing"));
//const NewPR = lazy(() => import('../modules/esource/new-pr'));
const PRPreview = lazy(() => import("../modules/esource/pr-preview"));
const POLanding = lazy(() => import("../modules/esource/po-landing"));
const POPreview = lazy(() => import("../modules/esource/po-preview"));
//const NewPO = lazy(() => import('../modules/esource/new-po'));
// invoice
const InvoiceLanding = lazy(() => import("../modules/invoicemgmnt/landing"));
//const CreateInvoice = lazy(() => import('../modules/invoicemgmnt/new-invoice'));
const NonPOInvoice = lazy(() =>
  import("../modules/invoicemgmnt/landing/non-po-invoice")
);
//const AdvancedInvoice = lazy(() => import('../modules/invoicemgmnt/advanced-invoice'));
const PreviewInvoice = lazy(() =>
  import("../modules/invoicemgmnt/preview-invoice")
);
// Service Management
//const ServiceLanding = lazy(() => import('../modules/servicemgmnt/landing'));
//const ServiceDetails = lazy(() => import('../modules/servicemgmnt/service-details'));
//const ServiceListing = lazy(() => import('../modules/servicemgmnt/service-listing'));
const ManageCategories = lazy(() =>
  import("../modules/servicemgmnt/categories")
);
const ManageCatalogue = lazy(() => import("../modules/servicemgmnt/catalogue"));
// Admin Management
const ManageUsers = lazy(() =>
  import("../modules/adminmgmnt/usermgmnt/manage-users")
);
const ManageRoles = lazy(() =>
  import("../modules/adminmgmnt/usermgmnt/manage-roles")
);
const DelegateRole = lazy(() =>
  import("../modules/adminmgmnt/usermgmnt/delegate-role")
);
const LookupParams = lazy(() =>
  import("../modules/adminmgmnt/administration/lookup-parameters")
);
const SetupNotification = lazy(() =>
  import("../modules/adminmgmnt/administration/setup-notification")
);
const ManageMasterData = lazy(() =>
  import("../modules/adminmgmnt/administration/manage-master-data")
);
const ViewMstLog = lazy(() =>
  import("../modules/adminmgmnt/administration/manage-master-data/view-log")
);
const MasterDataDetail = lazy(() =>
  import("../modules/adminmgmnt/administration/master-data-detail")
);
const InterfaceMonitor = lazy(() =>
  import("../modules/adminmgmnt/administration/interface-monitor")
);
const InterfaceMonitorDetail = lazy(() =>
  import("../modules/adminmgmnt/administration/interface-monitor-detail")
);
const DeptApproversSetup = lazy(() =>
  import("../modules/adminmgmnt/administration/dept-approvers-setup")
);
const AmtBasedApproversSetup = lazy(() =>
  import("../modules/adminmgmnt/administration/amtbased-approvers-setup")
);
//const TaskMonitor = lazy(() => import('../modules/adminmgmnt/administration/task-monitor'));
const NotificationDetail = lazy(() =>
  import("../modules/common/notification-detail")
);
const BasicSettings = lazy(() =>
  import("../modules/adminmgmnt/administration/basic-settings")
);
const CostCenters = lazy(() =>
  import("../modules/adminmgmnt/administration/cost-centers")
);
const OrganizationDocuments = lazy(() =>
  import("../modules/supplier/organization-docs")
);

const Reconciliations = lazy(() =>
  import("../modules/reconciliation")
);
const Reconciliationreport = lazy(() =>
  import("../modules/reconciliation/reconciliationreport")
);
// Spend Analysis
const SpendAnalysis = lazy(() => import("../modules/spend-analysis"));

const SpendDetails = lazy(() => import("../modules/spend-details"));

// Budgets
const Budgets = lazy(() => import("../modules/budgets/budget-landing"));
const PreviewBudget = lazy(() => import("../modules/budgets/budget-preview"));
// Inventory
const Inventory = lazy(() => import("../modules/inventory/inventory-landing"));
const InventoryPreview = lazy(() =>
  import("../modules/inventory/inventory-preview")
);
// Subscription
const SubscriptionMgmt = lazy(() => import("../modules/subscriptionmgmt"));
const SubscriptionCheckout = lazy(() =>
  import("../modules/subscriptionmgmt/checkout")
);
const ExpiredSubscription = lazy(() =>
  import("../modules/subscriptionmgmt/expired-subscription")
);
const PaymentResponse = lazy(() =>
  import("../modules/subscriptionmgmt/payment-response")
);
// Bids
const BidsLanding = lazy(() => import("../modules/bidsmgmt/bids-landing"));
const BidsCreate = lazy(() => import("../modules/bidsmgmt/bids-create"));
const BidsView = lazy(() => import("../modules/bidsmgmt/bids-view"));
const BidsSuppView = lazy(() => import("../modules/bidsmgmt/bids-supp-view"));
const SupplierBids = lazy(() =>
  import("../modules/bidsmgmt/bids-supplier-landing")
);
const BidsResponse = lazy(() => import("../modules/bidsmgmt/bids-response"));
const BidsResponseView = lazy(() =>
  import("../modules/bidsmgmt/bids-response-view")
);
const BidsEvaluateResponse = lazy(() =>
  import("../modules/bidsmgmt/bids-evaluate-response")
);
const BidsResponseLinescore = lazy(() =>
  import("../modules/bidsmgmt/bids-response-linescore")
);
const BidsTechResponsescore = lazy(() =>
  import("../modules/bidsmgmt/bids-tech-response")
);
const BidsTechScore = lazy(() =>
  import("../modules/bidsmgmt/bids-evaluate-response/bids-technical-score")
);
const BidsCommScore = lazy(() =>
  import("../modules/bidsmgmt/bids-evaluate-response/bids-comm-score")
);
const CompareResponses = lazy(() =>
  import("../modules/bidsmgmt/bid-compare-responses")
);
const AwardBidDetail = lazy(() =>
  import("../modules/bidsmgmt/bid-award-details")
);
// Contracts
const ContractsLanding = lazy(() =>
  import("../modules/contractsmgmt/contracts-landing")
);
const ContractsPreview = lazy(() =>
  import("../modules/contractsmgmt/contracts-preview")
);
const ContractsSupplierLanding = lazy(() =>
  import("../modules/contractsmgmt/contracts-supplier-landing")
);
const SupplierContractPreview = lazy(() =>
  import("../modules/contractsmgmt/contracts-supplier-preview")
);
const ContractsSections = lazy(() =>
  import("../modules/contractsmgmt/contracts-sections")
);
const ContractsTerms = lazy(() =>
  import("../modules/contractsmgmt/contracts-terms")
);

// My Tasks
const MyTasks = lazy(() => import("../modules/common/mytasks"));
// Reports
const MyReports = lazy(() => import("../modules/reports"));
const ReportView = lazy(() => import("../modules/reports/report-view"));
// Account Manage
const Account = lazy(() => import("../modules/common/account"));
// ProductsManage
const ManageProducts = lazy(() => import("../modules/products"));
const CreateProduct = lazy(() => import("../modules/products/create-product"));
const EditProduct = lazy(() => import("../modules/products/edit-product"));
const ViewProduct = lazy(() => import("../modules/products/view-product"));

//Auctions
const AuctionsLanding = lazy(() =>
  import("../modules/events")
);
const AuctionDetails = lazy(() => import('../modules/events/auction-details'))
const CompareAuctions = lazy(() => import('../modules/events/compare-bids'))
const SupplierAuctionLanding = lazy(() => import('../modules/events/supplier-auctions'))
const SupplierAuctionView = lazy(() => import('../modules/events/supplier-auction-view'))
const SupplierUpdateResponse = lazy(() => import('../modules/events/supplier-auction-update-response'))
const SupplierEditResponse = lazy(() => import('../modules/events/supplier-auction-edit-response'))
const SupplierAuctionResponseView = lazy(() => import('../modules/events/supplier-auction-response-view'))
const AwardAuctions = lazy(() => import('../modules/events/award-auctions'))
const AuctionDetailedView = lazy(() => import('../modules/events/detailed-view'))
const AwardAuctionDetail = lazy(() =>
  import("../modules/events/award-auction-details")
);
const AwardAuctionList = lazy(() =>
  import("../modules/events/award-auctions-list")
);


// loader
const loadingBlock = (
  <div className="loader-block">
    <div className="loader" />
  </div>
);
// routes list
const landingRoutes = [
  "/",
  "/signin",
  "/signInMsupplier",
  "/signup",
  "/forgotpassword",
  "/resetpassword",
  "/about-us",
  "/products",
  "/plans",
  "/free-trail",
  "/book-demo",
  "/contact-us",
  "/supplier-management",
  "/contract-management",
  "/purchase-requisition",
  "/e-invoicing",
  "/e-sourcing",
  "/spend-analytics",
  "/integrations",
  "/blogs",
  "/blog-details/:name",
  "/privacy-policy",
  "/terms-and-conditions",
  "/careers",
  "/supplier-user-manual",

];
const secondLevelRouters = [
  "/company-details",
  "/scope-of-supply",
  "/contact-details",
  "/banking",
  "/certificates",
  "/supplier-preview/:id",
];

/*Please Use below rolestatus to add restriction to routes
rolestatus = 1 (SUPERADMIN)
rolestatus = 2 (SYSADMIN)
rolestatus = 3 (PROCUREMENT_OFFICER)
rolestatus = 4 (PROCUREMENT_MANAGER)
rolestatus = 5 (DEPARTMENT_USER)
rolestatus = 6 (FINANCE_OFFICER)
rolestatus = 7 (FINANCE_MANAGER)
rolestatus = 8 (SUPPLIER_ADMIN)
*/

export default class AppRouter extends Component {
  render() {
    return (
      <Router>
        <Route exact path={landingRoutes}>
          <Suspense fallback={loadingBlock}>
            <Switch>
              {/* landing module */}
              <Route path="/" component={Signin} exact />
              <Route path="/signInMsupplier" component={SigninNew} />
              <Route path="/signin" component={Signin} />
              <Route path="/signup/:uid?" component={Signup} />
              <Route path="/forgotpassword" component={Forgotpassword} />
              <Route path="/resetpassword" component={Resetpassword} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/products" component={Products} />
              <Route path="/plans" component={Pricing} />
              <Route path="/free-trail" component={FreeTrail} />
              <Route path="/book-demo" component={BookDemo} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/supplier-management" component={SupplierManagement} />
              <Route path="/contract-management" component={ContractManagement} />
              <Route path="/purchase-requisition" component={PurchaseRequisition} />
              <Route path="/e-invoicing" component={EInvoicing} />
              <Route path="/e-sourcing" component={ESourcing} />
              <Route path="/spend-analytics" component={SpendAnalytics} />
              <Route path="/integrations" component={Integrations} />
              <Route path="/blogs" component={Blogs} />
              <Route path="/blog-details/:name" component={BlogDetails} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/supplier-user-manual" component={SupplierUserManual} />
              <Route path="/terms-and-conditions" component={PrivacyPolicy} />
              <Route path="/careers" component={Careers} />
            </Switch>
          </Suspense>
        </Route>

        <Route exact path={secondLevelRouters}>
          <Suspense fallback={loadingBlock}>
            <Switch>
              <Route path="/company-details" component={CompanyDetails} />
              <Route path="/contact-details" component={ContactDetails} />
              <Route path="/scope-of-supply" component={ScopeofSupply} />
              <Route path="/certificates" component={Certificates} />
              <Route path="/banking" component={Banking} />
              <Route path="/supplier-preview/:id" component={SupplierPreview} />
              <Route path="/my-tasks" component={MyTasks} />
            </Switch>
          </Suspense>
        </Route>

        <Route>
          <Suspense fallback={loadingBlock}>
            {/* <LayoutHOC> */}
            <Switch>
              {/* common module */}
              <HeaderLayout path="/forbidden" component={Forbidden} />
              <Route path="/access-denied" component={AccessDenied} />
              <Route path="/checkUser" component={UserCheck} />
              <HeaderLayout path="/account" component={Account} />
              <HeaderLayout path="/my-tasks" component={MyTasks} />
              <HeaderLayout
                path="/notification-detail"
                component={NotificationDetail}
              />
              <HeaderLayout path="/feedback" component={UserFeedback} />
              {/* dashboard module */}
              <HeaderLayout path="/dashboard" component={Dashboard} />
              {/* Catalouge module */}
              <HeaderLayout
                path="/manage-categories"
                component={ManageCategories}
              />
              <HeaderLayout
                path="/manage-catalogue"
                component={ManageCatalogue}
              />
              {/* Admin Management */}
              <HeaderLayout path="/basic-settings" component={BasicSettings} />
              <HeaderLayout
                path="/lookup-parameters"
                component={LookupParams}

              />
              <HeaderLayout path="/cost-centers" component={CostCenters} />
              <HeaderLayout
                path="/dept-approvers-setup"
                component={DeptApproversSetup}

              />
              <HeaderLayout
                path="/amtbased-approvers-setup"
                component={AmtBasedApproversSetup}

              />
              <HeaderLayout
                path="/setup-notification"
                component={SetupNotification}

              />
              {/* User Management Module*/}
              <HeaderLayout path="/manage-users" component={ManageUsers} />
              <HeaderLayout path="/manage-roles" component={ManageRoles} />
              <HeaderLayout path="/delegate-role" component={DelegateRole} />
              {/* supplier module */}
              <HeaderLayout path="/supplier" component={SupplierListing} />
              <HeaderLayout path="/createSupplierUser" component={SupplierUsers} />
              <HeaderLayout path="/orgDocuments" component={OrganizationDocuments} />
              <HeaderLayout path="/reconciliation" component={Reconciliations} />
              <HeaderLayout path="/reconciliation-report" component={Reconciliationreport} />
              <HeaderLayout path="/suppliers-users" component={SupplierUsers} />
              <HeaderLayout
                path="/supplier-approval/:id?"
                component={SupplierApproval}
                title="Profile Preview"
              // linkToBack="/supplier"
              />
              {/* esource */}
              <HeaderLayout path="/sourcing" component={PRLanding} />
              <HeaderLayout
                path="/purchase-preview/:id"
                component={PRPreview}
                title="Purchase Preview"
                linkToBack="/sourcing"
              />
              <HeaderLayout path="/po-landing" component={POLanding} />
              <HeaderLayout
                path="/po-preview/:id"
                component={POPreview}
                title="Purchase Order Preview"
                linkToBack="/po-landing"
              />
              {/* Invoices */}
              <HeaderLayout path="/invoices" component={InvoiceLanding} />
              <HeaderLayout path="/non-po-invoice" component={NonPOInvoice} />
              <HeaderLayout
                path="/invoice-preview/:id"
                component={PreviewInvoice}
                title="Invoice Preview"
                linkToBack="/invoices"
              />
              {/* Budget */}
              <HeaderLayout path="/budgets" component={Budgets} />
              <HeaderLayout
                path="/preview-budget/:id"
                component={PreviewBudget}
                title="Budgets Preview"
                linkToBack="/budgets"

              />
              {/* Integration Module*/}
              <HeaderLayout
                path="/manage-master-data"
                component={ManageMasterData}

              />
              <HeaderLayout
                path="/viewMstLog/:id"
                component={ViewMstLog}

              />
              <HeaderLayout
                path="/interface-monitor"
                component={InterfaceMonitor}

              />
              <HeaderLayout
                path="/interface-monitor-detail"
                component={InterfaceMonitorDetail}

              />
              <HeaderLayout
                path="/master-data-detail/:id"
                component={MasterDataDetail}

              />
              {/* Spend Analysis */}
              <HeaderLayout path="/spend-analysis" component={SpendAnalysis}
              />
              <HeaderLayout
                path="/spend-details"
                component={SpendDetails}
                linkToBack="/spend-analysis"
                title="Spend Details"

              />

              {/* Reports */}
              <HeaderLayout path="/myreports" component={MyReports}
              />
              <HeaderLayout
                path="/report-details/:name"
                component={ReportView}
                linkToBack="/myreports"
                title="Report Preview"

              />

              {/* Subscription Mgmt */}
              <HeaderLayout
                path="/my-subscription"
                component={SubscriptionMgmt}

              />
              <HeaderLayout
                path="/checkout"
                linkToBack="/my-subscription"
                title="Pay with Checkout"
                component={SubscriptionCheckout}

              />
              <HeaderLayout path="/end-trail" component={ExpiredSubscription}
              />
              <HeaderLayout
                path="/payment-response"
                component={PaymentResponse}

              />
              {/* Inventory */}
              <HeaderLayout path="/inventory" component={Inventory}
              />
              <HeaderLayout
                path="/inventory-preview/:id"
                component={InventoryPreview}
                title="Inventory Preview"
                linkToBack="/inventory"

              />
              {/* Bids */}
              <HeaderLayout path="/bids" component={BidsLanding} />
              <HeaderLayout
                path="/bid-create/:id"
                component={BidsCreate}
                title="Bids Create"
                linkToBack="/bids"
              />
              <HeaderLayout
                path="/bid-view/:id"
                component={BidsView}
                title="View Bid"
                linkToBack="/bids"
              />
              <HeaderLayout
                path="/bid-evaluate-response/:id"
                component={BidsEvaluateResponse}
                title="Analyze Bid Responses"
                linkToBack="/bids"
              />
              <HeaderLayout path="/supplierBids" component={SupplierBids} />
              <HeaderLayout
                path="/bid-supplierView/:id"
                component={BidsSuppView}
                title="View Bid"
                linkToBack="/supplierBids"
              />
              <HeaderLayout
                path="/bid-response/:id"
                component={BidsResponse}
                title="Bid Response"
                linkToBack="/supplierBids"
              />
              <HeaderLayout
                path="/bid-response-view/:id"
                component={BidsResponseView}
                title="View Bid Response"
                linkToBack="/supplierBids"
              />
              <HeaderLayout
                path="/bid-response-linescore/:id"
                component={BidsResponseLinescore}
                title="Bid Responses Scoring"
                linkToBack="/bids"
              />
              <HeaderLayout
                path="/bid-tech-linescore/:id"
                component={BidsTechResponsescore}
                title="Bid Responses Scoring"
                linkToBack="/bids"
              />
              <HeaderLayout
                path="/bid-tech-score/:id"
                component={BidsTechScore}
                title="Bid Responses"
                linkToBack="/bids"
              />
              <HeaderLayout
                path="/bid-comm-score/:id"
                component={BidsCommScore}
                title="Bid Commercial Scoring"
                linkToBack="/bids"
              />
              <HeaderLayout
                path="/bid-compare-responses/:id"
                component={CompareResponses}
                title="Compare Bids"
                linkToBack="/bids"
              />
              <HeaderLayout
                path="/bid-award-details/:id"
                component={AwardBidDetail}
                title="Bid Award Details"
                linkToBack="/bids"
              />
              {/* Contracts */}
              <HeaderLayout path="/contracts" component={ContractsLanding} />
              <HeaderLayout
                path="/contract-preview/:id"
                component={ContractsPreview}
                title="Contract Preview"
                linkToBack="/contracts"
              />
              <HeaderLayout
                path="/supplierContracts"
                component={ContractsSupplierLanding}
              />
              <HeaderLayout
                path="/contract-supplierView/:id"
                component={SupplierContractPreview}
                title="View Contract"
                linkToBack="/supplierContracts"
              />
              <HeaderLayout
                path="/contractSections"
                component={ContractsSections}
              />
              <HeaderLayout
                path="/contractTerms"
                component={ContractsTerms}
              />
              {/*Products Manage*/}
              <HeaderLayout
                path="/manage-items"
                component={ManageProducts}

              />
              <HeaderLayout path="/create-item" component={CreateProduct}
              />
              <HeaderLayout path="/edit-item/:id" component={EditProduct}
              />
              <HeaderLayout path="/view-item/:id" component={ViewProduct}
              />
              {/*Events Manage*/}
              <HeaderLayout path="/manage-auctions" component={AuctionsLanding} />
              <HeaderLayout
                path="/auction-details/:id"
                component={AuctionDetails}
                title="View Auction"
                linkToBack="/manage-auctions"
              />
              <HeaderLayout
                path="/compare-bids"
                component={CompareAuctions}
                title="COMPARATIVE STATEMENT"
                linkToBack="/manage-auctions"
              />
              <HeaderLayout path="/supplierAuctions" component={SupplierAuctionLanding} />
              <HeaderLayout
                path="/supplierAuction-view/:id"
                component={SupplierAuctionView}
                title="Back"
                linkToBack="/supplierAuctions"
              />
              <HeaderLayout
                path="/supplierAuction-update-response/:id"
                component={SupplierUpdateResponse}
                title="Back"
                linkToBack="/supplierAuctions"
              />
              <HeaderLayout
                path="/supplierAuction-edit-response/:id"
                component={SupplierEditResponse}
                title="Back"
                linkToBack="/supplierAuctions"
              />
              <HeaderLayout
                path="/supplierAuction-response-view/:id"
                component={SupplierAuctionResponseView}
                title="Back"
                linkToBack="/supplierAuctions"
              />
              <HeaderLayout
                path="/award-auctions/:id"
                component={AwardAuctions}
                title="Award Bid"
              // linkToBack="/manage-auctions"
              />
              <HeaderLayout
                path="/auction-detailed-view/:id"
                component={AuctionDetailedView}
                title="Detailed View"
                linkToBack="/manage-auctions"
              />
              <HeaderLayout
                path="/auction-award-details/:id"
                component={AwardAuctionDetail}
                title="Auction Award Details"
                linkToBack="/manage-auctions"
              />
              <HeaderLayout
                path="/auction-award-list/:id"
                component={AwardAuctionList}
                title="Auction Award Details"
                linkToBack="/manage-auctions"
              />
            </Switch>
            {/* </LayoutHOC> */}
          </Suspense>
        </Route>
        {/* ChatBot */}
        <ChatBot />
        {/* ChatBot End */}
        <ToastContainer
          autoClose={3000}
          limit={1}
          rtl={false}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ width: "auto" }}
        />
      </Router>
    );
  }
}
