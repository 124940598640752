import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../components/icon';
import { convertDateFormat } from "../../../functions/common";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastLogin: ""
    }
  }
  componentDidMount() {
    setInterval(() => {
      const loggedIn = localStorage.getItem("lastLogin");
      this.setState({ lastLogin: loggedIn });
    }, 5000)
  }
  render() {
    return (
      <footer>
        <div className="footer-block">
          <div className="copyright">
            Copyright@{new Date().getFullYear()} Prokraya Tech Pvt. Ltd. All rights reserved.
          </div>
          <div className="login-block">
            Last Login: <span style={{ color: "#206ecc", fontWeight: "bold" }}>{this.state.lastLogin ? convertDateFormat(this.state.lastLogin, `DD-MM-YYYY hh:mm a`
            ) : "N/A"}</span>
          </div>
          <div className="help-block">
            <Icon iconName="help mr-1" iconSize={20} />
            Help
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
