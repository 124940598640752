import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import Icon from "../../../components/icon";
import { CircularBar } from "../../../components/progressbar";
// import logo from '../../../assets/images/logo.jpg';/../
// import logo from "../../../assets/images/prokraya-logo-new1.png";
import logo from "../../../assets/images/msupplier-logo-white.png"
// import logosm from '../../../assets/images/logo-sm.jpg';
// import logosm from "../../../assets/images/prokraya-sm.png";
import logosm from "../../../assets/images/msupplier-smlogo.png";
import HttpServices from "../../../environments/environment_urls";
import { env, env_url } from "../../../environments/env";
import Customtoast from "../../../components/customtoast";

class Leftsidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userState: 2,
      previewId: "",
      supplierStatus: "",
      companyStatus: false,

      superAdminMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "sd_suppliers",
          menuName: "Suppliers",
          url: "/supplier",
          subUrls: [
            {
              id: 1,
              name: "Supplier",
              url: "/new-po",
            },
            {
              id: 2,
              name: "Procurement Officer",
              url: "/new-pr",
            },
          ],
        },
        {
          id: 3,
          iconName: "sd_products-services",
          menuName: "Catalogue",
          url: "/manage-catalogue",
          subUrls: [
            {
              id: 1,
              name: "Supplier",
              url: "/dashboard-o",
            },
            {
              id: 2,
              name: "Procurement Officer",
              url: "/p-dashboard",
            },
          ],
        },
        {
          id: 4,
          iconName: "sd_sourcing",
          menuName: "Requisitions",
          url: "/sourcing",
          subUrls: [
            {
              id: 1,
              name: "Supplier",
              url: "/dashboard-o",
            },
            {
              id: 2,
              name: "Procurement Officer",
              url: "/p-dashboard",
            },
          ],
        },
        {
          id: 5,
          iconName: "sd_sourcing",
          menuName: "Purchase Orders",
          url: "/po-landing",
          // subUrls: ['/po-preview', '/new-po']
        },
        {
          id: 6,
          iconName: "sd_invoices",
          menuName: "Invoices",
          url: "/invoices",
          // subUrls: ['/create-invoice', '/non-po-invoice', '/advanced-invoice', '/invoice-preview']
        },
        {
          id: 7,
          iconName: "administration",
          menuName: "Administration",
          url: "/basic-settings",
          // subUrls: ['/lookup-parameters', '/setup-notification', '/dept-approvers-setup', '/cost-centers']
        },
        {
          id: 8,
          iconName: "user-management",
          menuName: "User Management",
          url: "/manage-users",
          // subUrls: ['/manage-roles', '/delegate-role']
        },
        {
          id: 9,
          iconName: "sd_mytask",
          menuName: "Integrations ",
          url: "/interface-monitor",
          // subUrls: ['/interface-monitor-detail', '/manage-master-data', '/master-data-detail']
        },
        {
          id: 10,
          iconName: "sd_mytask",
          menuName: "Budgets",
          url: "/budgets",
          // subUrls: []
        },
        {
          id: 11,
          iconName: "sd_spend-analysis",
          menuName: "Spend Analysis",
          url: "/spend-analysis",
        },
        {
          id: 12,
          iconName: "sd_myreports",
          menuName: "Reports",
          url: "/myreports",
          // subUrls: ['/report-details']
        },
      ],

      adminMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "administration",
          menuName: "Administration",
          url: "/basic-settings",
          subUrls: [
            "/lookup-parameters",
            "/setup-notification",
            "/dept-approvers-setup",
            "/cost-centers",
          ],
        },
        {
          id: 3,
          iconName: "user-management",
          menuName: "User Management",
          url: "/manage-users",
          subUrls: ["/manage-roles", "/delegate-role"],
        },
        {
          id: 4,
          iconName: "sd_mytask",
          menuName: "Integrations ",
          url: "/interface-monitor",
          subUrls: [
            "/interface-monitor-detail",
            "/manage-master-data",
            "/master-data-detail",
          ],
        },
      ],

      procOfficerMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "sd_suppliers",
          menuName: "Suppliers",
          url: "/supplier",
          subUrls: [
            "/supplier-approval",
            "/feedback",
            "/survey",
            "/new-survey",
            "/survey-template",
            "/create-template",
            "/select-suppliers",
          ],
        },
        {
          id: 3,
          iconName: "sd_products-services",
          menuName: "Catalogue",
          url: "/manage-catalogue",
          subUrls: [
            "/service-details",
            "/service-listing",
            "/generate-pr",
            "/service-landing",
            "/manage-categories",
          ],
        },
        {
          id: 4,
          iconName: "sd_sourcing",
          menuName: "Requisitions",
          url: "/sourcing",
          subUrls: ["/new-pr", "/purchase-preview"],
        },
        {
          id: 5,
          iconName: "sd_sourcing",
          menuName: "Purchase Orders",
          url: "/po-landing",
          subUrls: ["/po-preview", "/new-po"],
        },
        {
          id: 6,
          iconName: "sd_invoices",
          menuName: "Invoices",
          url: "/invoices",
          subUrls: [
            "/create-invoice",
            "/non-po-invoice",
            "/advanced-invoice",
            "/invoice-preview",
          ],
        },
        {
          id: 7,
          iconName: "sd_myreports",
          menuName: "Reports",
          url: "/myreports",
          subUrls: ["/report-details"],
        },
      ],

      procMgmrMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "sd_suppliers",
          menuName: "Suppliers",
          url: "/supplier",
          subUrls: [
            "/supplier-approval",
            "/feedback",
            "/survey",
            "/new-survey",
            "/survey-template",
            "/create-template",
            "/select-suppliers",
          ],
        },
        {
          id: 3,
          iconName: "sd_products-services",
          menuName: "Catalogue",
          url: "/manage-catalogue",
          subUrls: [
            "/service-details",
            "/service-listing",
            "/generate-pr",
            "/service-landing",
            "/manage-categories",
          ],
        },
        {
          id: 4,
          iconName: "sd_sourcing",
          menuName: "Requisitions",
          url: "/sourcing",
          subUrls: ["/new-pr", "/purchase-preview"],
        },
        {
          id: 5,
          iconName: "sd_sourcing",
          menuName: "Purchase Orders",
          url: "/po-landing",
          subUrls: ["/po-preview", "/new-po"],
        },
        {
          id: 6,
          iconName: "sd_invoices",
          menuName: "Invoices",
          url: "/invoices",
          subUrls: [
            "/create-invoice",
            "/non-po-invoice",
            "/advanced-invoice",
            "/invoice-preview",
          ],
        },
        {
          id: 7,
          iconName: "sd_spend-analysis",
          menuName: "Spend Analysis",
          url: "/spend-analysis",
        },
        {
          id: 8,
          iconName: "sd_myreports",
          menuName: "Reports",
          url: "/myreports",
          subUrls: ["/report-details"],
        },
      ],

      finOfficerMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "sd_suppliers",
          menuName: "Suppliers",
          url: "/supplier",
          subUrls: [
            "/supplier-approval",
            "/feedback",
            "/survey",
            "/new-survey",
            "/survey-template",
            "/create-template",
            "/select-suppliers",
          ],
        },
        {
          id: 3,
          iconName: "sd_sourcing",
          menuName: "Purchase Orders",
          url: "/po-landing",
          subUrls: ["/po-preview", "/new-po"],
        },
        {
          id: 4,
          iconName: "sd_invoices",
          menuName: "Invoices",
          url: "/invoices",
          subUrls: [
            "/create-invoice",
            "/non-po-invoice",
            "/advanced-invoice",
            "/invoice-preview",
          ],
        },
        {
          id: 5,
          iconName: "sd_mytask",
          menuName: "Budgets",
          url: "/budgets",
          subUrls: [],
        },
        {
          id: 6,
          iconName: "sd_myreports",
          menuName: "Reports",
          url: "/myreports",
          subUrls: ["/report-details"],
        },
      ],

      finManagerMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "sd_suppliers",
          menuName: "Suppliers",
          url: "/supplier",
          subUrls: [
            "/supplier-approval",
            "/feedback",
            "/survey",
            "/new-survey",
            "/survey-template",
            "/create-template",
            "/select-suppliers",
          ],
        },
        {
          id: 3,
          iconName: "sd_sourcing",
          menuName: "Purchase Orders",
          url: "/po-landing",
          subUrls: ["/po-preview", "/new-po"],
        },
        {
          id: 4,
          iconName: "sd_invoices",
          menuName: "Invoices",
          url: "/invoices",
          subUrls: [
            "/create-invoice",
            "/non-po-invoice",
            "/advanced-invoice",
            "/invoice-preview",
          ],
        },
        {
          id: 5,
          iconName: "sd_mytask",
          menuName: "Budgets",
          url: "/budgets",
          subUrls: [],
        },
        {
          id: 6,
          iconName: "sd_spend-analysis",
          menuName: "Spend Analysis",
          url: "/spend-analysis",
        },
        {
          id: 7,
          iconName: "sd_myreports",
          menuName: "Reports",
          url: "/myreports",
          subUrls: ["/report-details"],
        },
      ],

      deptUserMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "sd_sourcing",
          menuName: "Requisitions",
          url: "/sourcing",
          subUrls: ["/new-pr", "/purchase-preview"],
        },
        {
          id: 3,
          iconName: "sd_sourcing",
          menuName: "Purchase Orders",
          url: "/po-landing",
          subUrls: ["/po-preview", "/new-po"],
        },
        {
          id: 4,
          iconName: "sd_invoices",
          menuName: "Invoices",
          url: "/invoices",
          subUrls: [
            "/create-invoice",
            "/non-po-invoice",
            "/advanced-invoice",
            "/invoice-preview",
          ],
        },
      ],

      supplierMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "sd_suppliers",
          menuName: "Registration Profile",
          url: "/supplier-approval/",
          subUrls: ["/supplier-approval"],
        },
        {
          id: 3,
          iconName: "sd_sourcing",
          menuName: "Purchase Orders",
          url: "/po-landing",
          subUrls: ["/po-preview"],
        },
        {
          id: 4,
          iconName: "sd_invoices",
          menuName: "Invoices",
          url: "/invoices",
          subUrls: ["/create-invoice", "/advanced-invoice", "/invoice-preview"],
        },
      ],
      collapsed: false,
      isActive: "",
      menuList: [],
      approvalStatus: false,
      openedState: "",
      toggled: false,
      toastInpErrorMessage: ""
    };
    this.toastInpError = React.createRef();
  }

  componentDidMount() {
    this.setState({ supplierStatus: localStorage.getItem("supplierStatus") });
    const token = localStorage.getItem("token");
    const userName = localStorage.getItem("userName");
    if (token !== "InValid User") {
      const form = new FormData();
      form.append("userName", userName);
      HttpServices.postData(
        env_url.loadUser, form
      ).then((resp) => {
        const userDetails = resp.data;
        env.userInfo = resp.data;
        if (resp.data) {
          const bol = userDetails.authorities.filter(
            (user) => user.authority === "SUPERADMIN"
          );
          if (bol.length > 0) {
            localStorage.setItem("rolestatus", 1);
          }

          const sybol = userDetails.authorities.filter(
            (user) => user.authority === "SYSADMIN"
          );
          if (sybol.length > 0) {
            localStorage.setItem("rolestatus", 2);
          }

          const pbol = userDetails.authorities.filter(
            (user) => user.authority === "PROCUREMENT_OFFICER"
          );
          if (pbol.length > 0) {
            localStorage.setItem("rolestatus", 3);
          }

          const pmbol = userDetails.authorities.filter(
            (user) => user.authority === "PROCUREMENT_MANAGER"
          );
          if (pmbol.length > 0) {
            localStorage.setItem("rolestatus", 4);
          }

          const dbol = userDetails.authorities.filter(
            (user) => user.authority === "DEPARTMENT_USER"
          );
          if (dbol.length > 0) {
            localStorage.setItem("rolestatus", 5);
          }

          const fobol = userDetails.authorities.filter(
            (user) => user.authority === "FINANCE_OFFICER"
          );
          if (fobol.length > 0) {
            localStorage.setItem("rolestatus", 6);
          }

          const fmbol = userDetails.authorities.filter(
            (user) => user.authority === "FINANCE_MANAGER"
          );
          if (fmbol.length > 0) {
            localStorage.setItem("rolestatus", 7);
          }

          const sbol = userDetails.authorities.filter(
            (user) => user.authority === "SUPPLIER_ADMIN" || user.authority === "SUPPLIER_USER"
          );
          if (sbol.length > 0) {
            localStorage.setItem("rolestatus", 8);
          }
          const userState = Number(localStorage.getItem("rolestatus"));
          this.setState({ userState });
          this.getAllMenues(userDetails);
          localStorage.setItem("lastLogin", userDetails.lastLoginDate);
        }
        localStorage.setItem("suppId", resp.data.businessEntity.attribute1);
      })
        .catch((error) => {
          console.log(error);
        });
    }
    this.getSupplierUserAccount();
    this.getActiveMenu();
    this.checkActiveSubmenu();
    // if (this.props.pageLevel === 1) {
    //   document.querySelector('.content-block').classList.add('collapsedSidebar');
    // }
  }

  getAllMenues = (userDetails) => {
    let menuList = [];
    for (var key in userDetails.menus) {
      const subMenuList = userDetails.menus[key]
        .filter((element, index) => {
          if (index >= 0) {
            return element;
          }
        })
        .map((menu) => {
          return {
            id: menu.id,
            name: menu.description,
            url: "/" + menu.functionUrl,
          };
        });
      if (userDetails.menus[key].length > 1) {
        menuList.push({
          id: userDetails.menus[key][0].id,
          iconName: userDetails.menus[key][0].iconName,
          menuName: userDetails.menus[key][0].moduleName,
          url: "/" + userDetails.menus[key][0].functionUrl,
          activeUrl: false,
          subUrls: subMenuList,
        });
      } else {
        menuList.push({
          id: userDetails.menus[key][0].id,
          iconName: userDetails.menus[key][0].iconName,
          menuName: userDetails.menus[key][0].moduleName,
          url: "/" + userDetails.menus[key][0].functionUrl,
          activeUrl: false,
        });
      }
    }
    this.setState({ menuList });
    this.getActiveMenu();
  };

  getActiveMenu = () => {
    let menuList = this.state.menuList;
    let mainmenu = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    if (menuList) {
      for (let i = 0; i < menuList.length; i++) {
        const element = menuList[i];
        if (!element.subUrls && element.url.replace("/", "") === mainmenu) {
          element.activeUrl = true;
        } else if (element.subUrls) {
          element.activeUrl = false;
          this.checkActiveSubmenu(menuList, element);
        }
      }
    }
    this.setState({ menuList });
  };

  getSupplierUserAccount = () => {
    this.setState({ loader: true });
    const url = env_url.getCompanyDetails;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        localStorage.setItem("profilePercentage", response.data.supplierObj.attribute15 ? response.data.supplierObj.attribute15 : 0)
        let suppUserObj = response.data.supplierObj;
        if (suppUserObj.status !== null) {
          this.setState({ companyStatus: true });
        }
        if ((suppUserObj.status === "Pending Approval" || suppUserObj.status === "Rejected") && suppUserObj.attribute15 === null) {
          localStorage.setItem("supplierStatus", "Pending");
          this.setState({ approvalStatus: true });
        }
        if (suppUserObj.status === "More Info Required" && suppUserObj.supplierDetailsModified === null) {
          localStorage.setItem("supplierStatus", "Pending");
          this.setState({ approvalStatus: false });
        }
        if (suppUserObj.status === "Rejected") {
          localStorage.setItem("rejectedSupplier", "Rejected");
        }
        const menuList = this.state.supplierMenuList.map((item) => {
          if (2 === item.id) return { ...item, url: item.url + suppUserObj.id };
          return { ...item };
        });
        this.setState({
          supplierMenuList: menuList,
          previewId: suppUserObj.id,
          loader: false,
        });
      }
    });
  };

  toggleSidebar = () => {
    document
      .querySelector(".content-block")
      .classList.toggle("collapsedSidebar");
  };

  checkActiveSubmenu = (menuList, menuItem) => {
    if (menuList && menuItem) {
      for (let i = 0; i < menuList.length; i++) {
        const element = menuList[i];
        if (
          element.id === menuItem.id &&
          element.subUrls.some(
            (item) =>
              item.url ===
              "/" +
              window.location.href.substring(
                window.location.href.lastIndexOf("/") + 1
              )
          )
        ) {
          element.activeUrl = true;
          break;
        } else {
          element.activeUrl = false;
        }
      }
      this.setState({ menuList });
    }
  };

  menuIconClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.toggleSidebar();
    console.log("11111");
  };

  getActiveMenuItem = (selectedUrl) => {
    this.props.handleToggleSidebar(false);
    this.setState({ openedState: "" });
    let previousUrl = window.location.pathname;
    let menuList = this.state.menuList;
    let subUrlsInactive = menuList.map((item) => {
      if (item.subUrls) {
        const suburl = item.subUrls.map((subUrl) => {
          subUrl.activeUrl = false;
          return subUrl;
        });
        item.subUrls = suburl;
        return item;
      } else {
        return item;
      }
    });

    // urls that are not part of urls or suburls
    // const prevURL = previousUrl.split("/");
    // const previewUrls = [
    //   "/purchase-preview",
    //   "/invoice-preview",
    //   "/po-preview",
    //   "/supplier-approval",
    //   "/my-tasks",
    //   "/notification-detail",
    //   "/report-details",
    // ];
    //

    const updateActiveState = subUrlsInactive.map((item) => {
      const prevURL = previousUrl.split("/");
      const previewUrls = [
        "/purchase-preview",
        "/invoice-preview",
        "/po-preview",
        "/supplier-approval",
        "/my-tasks",
        "/notification-detail",
        "/feedback",
        "/account",
        "/preview-budget",
        "/report-details",
        "/inventory-preview",
        "/bid-view",
        "/create-item",
        "/view-item",
        "/edit-item",
      ];
      if (item.url === previousUrl) {
        let url = item;
        url.activeUrl = false;
        return url;
      } else if (selectedUrl.url === item.url) {
        let url = item;
        url.activeUrl = true;
        return url;
      } else if (prevURL.length > 0 && previewUrls.includes(`/${prevURL[1]}`)) {
        let url = item;
        url.activeUrl = false;
        return url;
      } else {
        return item;
      }
    });
    this.setState({ menuList: updateActiveState });
  };
  getSubActiveMenu = (menuItem, subMenu) => {
    this.props.handleToggleSidebar(false);
    let menuList = this.state.menuList;
    const finalActiveList = [];
    menuList.forEach((item) => {
      // let finalActiveList = []
      if (item.subUrls) {
        item.activeUrl = false;

        const ActiveSubUrl = item.subUrls.map((suburl) => {
          if (suburl.url === subMenu) {
            suburl.activeUrl = true;
            return suburl;
          } else {
            suburl.activeUrl = false;
            return suburl;
          }
        });
        item.subUrls = ActiveSubUrl;
        finalActiveList.push(item);
      } else {
        item.activeUrl = false;
        finalActiveList.push(item);
      }
    });
    this.setState({ menuList: finalActiveList });
  };

  onOpenChange = (menuItem) => {
    let count = 0;
    let menuList = this.state.menuList;
    if (menuItem.id === this.state.openedState) {
      this.setState({ openedState: "" });
    } else {
      for (let i = 0; i < menuList.length; i++) {
        const element = menuList[i];
        if (element.id === menuItem.id) {
          if (count % 2 === 0) {
            this.setState({ openedState: menuItem.id });
          } else {
            this.setState({ openedState: "" });
          }
        }
      }
    }
  };

  render() {
    const state = this.state;
    const props = this.props;
    return (
      <React.Fragment>
        {(props.supplier || state.supplierStatus === "Pending") && (
          <React.Fragment>
            <ProSidebar
              collapsed={state.collapsed}
              breakPoint="md"
              toggled={props.toggled}
              onToggle={props.handleToggleSidebar}
            >
              <SidebarHeader>
                <div className="block-logo">
                  <Link to="/">
                    <img src={state.collapsed ? logosm : logo} alt="logo" />
                  </Link>
                </div>
                <div className="toggle-block" onClick={this.menuIconClick} />
              </SidebarHeader>
              <SidebarContent>
                <Menu iconShape="square">
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedCompanyDetails ? "complete" : "") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="company-details" iconSize={22} />}
                    active={window.location.pathname === "/company-details"}
                  >
                    <Link
                      to="/company-details"
                      className={
                        "menu-list-name " +
                        (props.completedCompanyDetails ? "complete" : "") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Company Details</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedContactReference ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="contact-reference" iconSize={22} />}
                    active={window.location.pathname === "/contact-details"}
                  >
                    <Link
                      to="/contact-details"
                      className={
                        "menu-list-name " +
                        (props.completedContactReference ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Contacts</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedScopeofSupply ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="scope-of-supply" iconSize={22} />}
                    active={window.location.pathname === "/scope-of-supply"}
                  >
                    <Link
                      to="/scope-of-supply"
                      className={
                        "menu-list-name " +
                        (props.completedScopeofSupply ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Scope of Supply</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedBanking ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="banking" iconSize={22} />}
                    active={window.location.pathname === "/banking"}
                  >
                    <Link
                      to="/banking"
                      className={
                        "menu-list-name " +
                        (props.completedBanking ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Banking</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedCertificates ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="certificates" iconSize={22} />}
                    active={window.location.pathname === "/certificates"}
                  >
                    <Link
                      to="/certificates"
                      className={
                        "menu-list-name " +
                        (props.completedCertificates ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Certificates</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedCertificates ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu")
                    }
                    icon={<Icon iconName="administration" iconSize={22} />}
                    active={window.location.pathname === "/supplier-preview/"}
                  >
                    <Link
                      to={"/supplier-preview/" + this.state.previewId}
                      className={
                        "menu-list-name " +
                        (props.completedCertificates ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Review Profile</span>
                    </Link>
                  </MenuItem>
                  <CircularBar
                    className="mt-5"
                    completed={
                      props.completedProgress ? props.completedProgress : 0
                    }
                    addition={"%"}
                    total={100}
                    LabelVal={
                      props.completedProgress ? props.completedProgress : 0
                    }
                    showlabel
                    label="Profile Completion"
                  />
                </Menu>
              </SidebarContent>
              <SidebarFooter />
            </ProSidebar>
          </React.Fragment>
        )}

        {props.showMenu &&
          !props.supplier &&
          state.supplierStatus !== "Pending" && (
            <ProSidebar
              collapsed={state.collapsed}
              breakPoint="md"
              toggled={props.toggled}
              onToggle={props.handleToggleSidebar}
            >
              <SidebarHeader>
                <div className="block-logo">
                  <Link to="/">
                    <img src={state.collapsed ? logosm : logo} alt="logo" />
                  </Link>
                </div>
                <div className="toggle-block" onClick={this.menuIconClick} />
              </SidebarHeader>
              <SidebarContent>
                {state.menuList.map((menuitem) => (
                  <Menu iconShape="square" key={menuitem.id}>
                    {menuitem.subUrls ? (
                      <SubMenu
                        open={state.openedState === menuitem.id ? true : false}
                        onOpenChange={() => this.onOpenChange(menuitem)}
                        title={menuitem.menuName}
                        className={menuitem.activeUrl ? "active" : ""}
                        icon={
                          <Icon iconName={menuitem.iconName} iconSize={22} />
                        }
                        style={{
                          pointerEvents: props.disableBlock ? "none" : "auto",
                          opacity: props.disableBlock ? 0.5 : 1,
                        }}
                      >
                        {menuitem.subUrls.map((submenu) => (
                          <MenuItem
                            className={submenu.activeUrl ? "active" : ""}
                            key={submenu.id}
                          >
                            <Link
                              to={submenu.url}
                              onClick={() =>
                                this.getSubActiveMenu(menuitem, submenu.url)
                              }
                            >
                              {submenu.name}
                            </Link>
                          </MenuItem>
                        ))}
                      </SubMenu>
                    ) : (
                      <MenuItem
                        className={menuitem.activeUrl ? "active" : ""}
                        icon={
                          <Icon iconName={menuitem.iconName} iconSize={22} />
                        }
                        style={{
                          pointerEvents: props.disableBlock ? "none" : "auto",
                          opacity: props.disableBlock ? 0.5 : 1,
                        }}
                      >
                        <Link
                          to={menuitem.url}
                          onClick={() => this.getActiveMenuItem(menuitem)}
                        >
                          {menuitem.menuName}
                        </Link>
                      </MenuItem>
                    )}
                  </Menu>
                ))}
              </SidebarContent>
              <SidebarFooter />
            </ProSidebar>
          )}
        <Customtoast
          ref={this.toastInpError}
          position="bottom-left"
          toastMsg={this.state.toastInpErrorMessage}
          error
        />
      </React.Fragment>
    );
  }
}

export default Leftsidebar;
