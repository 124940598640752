import React, { useState } from "react";
import Header from "../header";
import Leftsidebar from "../leftsidebar";
import Footer from "../footer";

const LayoutHOC = (props) => {
  const currentPath = window.location.pathname;
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {currentPath !== "/" && (
        <section className="content-block">
          {props.pageLevel === 2 ? (
            <React.Fragment>
              <Leftsidebar
                supplier
                completedProgress={100}
                completedCompanyDetails
                completedScopeofSupply
                completedContactReference
                completedBanking
                completedCertificates
                disableBlock={props.disableBlock}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
              <div className="container-fluid block-pads">
                <Header
                  titleText={props.titleText}
                  linkToBack={props.linkToBack}
                  supplier
                  disableBlock={props.disableBlock}
                  handleToggleSidebar={handleToggleSidebar}
                />
                {props.children}
                <Footer />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Leftsidebar
                pageLevel={1}
                showMenu={true}
                disableBlock={props.disableBlock}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
              <div className="container-fluid block-pads">
                <Header
                  titleText={props.titleText}
                  linkToBack={props.linkToBack}
                  ref={props.headerRef}
                  disableBlock={props.disableBlock}
                  handleToggleSidebar={handleToggleSidebar}
                />
                {props.children}
                <Footer />
              </div>
            </React.Fragment>
          )}
        </section>
      )}
    </>
  );
};

export default LayoutHOC;
