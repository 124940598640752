import React from "react";
import ReactDOM from "react-dom";
// import { ErrorBoundary } from "react-error-boundary";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/styles.scss";
import AppRouter from "./routes";
// import { ErrorFallback } from "./routes/PrivateRoute";
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VFhiQlRPcEBAXHxLflF1VWVTf1h6d11WESFaRnZdQV1mS3dTdUZlWnlaeHJS');

ReactDOM.render(
  // <ErrorBoundary FallbackComponent={ErrorFallback}>
    <AppRouter />,
  // </ErrorBoundary>,
  document.getElementById("prokraya-app")
);
